<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <!-- <CHeaderBrand class="mx-auto d-lg-none d-md-none d-xs-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand> -->
    <CHeaderNav class="d-md-down-none mr-auto">
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink> -->
      <!-- </CHeaderNavItem> -->
    </CHeaderNav>
    <CHeaderNav>
      <!--  -->
      <!-- <CHeaderNavLink>
        <span @click="gotoHelp()">
          <img src="@/assets/IMAGES/help.png" width="30" height="30"/><br>
        </span>
        </CHeaderNavLink> -->

        <CHeaderNavLink> 
  
    <CNavbarNav>
      <CDropdown toggler-text="Help "  >
        
        <CDropdownMenu>
          <CDropdownItem @click="gotoHelp()">
            User's Manual</CDropdownItem>
         
        </CDropdownMenu>
      </CDropdown>
    </CNavbarNav>
 
</CHeaderNavLink>




    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  mounted(){
    var user = window.localStorage.getItem('user_data');
    if(user == null){
      
      this.$router.push('/pages/login');
    }
  },
  methods : {
    gotoHelp() {
      if(this.is_roadwise())
      window.open('/help_file_roadwise/New Smartfleet User Manual.html', '_blank','height=600,width=1000,left=300,top=50,location=no')
      else if (this.is_monocrete())
      window.open('/help_file/New Smartfleet User Manual.html', '_blank','height=600,width=1000,left=300,top=50,location=no')
      else 
      window.open('/help_file_marvel/New Smartfleet User Manual.html', '_blank','height=600,width=1000,left=300,top=50,location=no')
      
    }
    
  }
}
</script>
