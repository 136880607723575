<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://coreui.io" target="_blank">Copyright</a>
      <!-- <span class="ml-1">&copy; {{new Date().getFullYear()}} SLOOP</span> -->
       <span class="ml-1">&copy; 2022 SMARTFLEET</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">Pahrsek</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
