<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img src="/Monocrete-New-Logo.png" style="height: 55px;" v-if="company.includes('Monocrete') || company.includes('monocrete') " />
       <img src="@/assets/IMAGES/roadwise.png" style="height: 55px;" v-if="company.includes('Roadwise') || company.includes('roadwise') " />
      <img src="http://marveltrucking.com/wp-content/uploads/2019/03/marvellogo.png" style="height: 55px;" v-if="company.includes('Marvel') || company.includes('marvel') "/>
      <!-- <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      /> -->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>

import config from '../views/config.js';
import nav from './_nav'
export default {
  name: 'TheSidebar',
  nav,
  data(){
    return{
      company: ""
    }
  },
  mounted(){
    this.company = null;
    if(config.getUserDetail('company')){
      this.company = config.getUserDetail('company').company_name;
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
